
.bread-component {
    display: flex;
    margin-top: 14px;
    margin-bottom: 12px;
    align-items: center;
    p {
        line-height: 22px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        color: #404040;
        a {
            display: block;
            font-size: 16px;
            line-height: 22px;
            font-family: Microsoft YaHei;
            color: #404040;
        }
    }
    p >>> .role4 {
        vertical-align: middle;
        width: 16px !important;
        height: 16px !important;
        margin-top: -2px;
    }
}
